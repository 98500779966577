import { format } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { PhotoModel } from '../../../../../../domain/model/PhotoModel';
import { PoiProxy } from '../../../../../../domain/proxy/PoiProxy';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { transient } from '../../../../../../inversify/decorator';
import { IViewGalleryProps } from './ViewImageGallery';

@transient()
export class ViewImageGalleryVm extends ViewModel<IViewGalleryProps> {

  @observable
  public lightboxIndex: number | null = null;

  @observable
  public photoToDelete: PhotoModel | null = null;

  constructor(
    @inject(PoiProxy) private readonly poiProxy: PoiProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setLightboxIndex = (index: number | null) => {
    this.lightboxIndex = index;
  }

  @action
  public setPhotoToDelete = (photo: PhotoModel | null) => {
    this.photoToDelete = photo;
  }

  @computed
  private get photos(): PhotoModel[] {
    return this.props.poi?.photos || [];
  }

  @computed
  public get groupedPhotos() {
    return this.groupPhotosByDate(this.photos);
  }

  @computed
  public get slides() {
    return this.photos.map((photo, index) => ({
      src: photo.url || '',
      title: `${photo.name || `Image ${index}`} - ${photo.createdAt ? format(new Date(photo.createdAt), 'dd.MM.yyyy HH:mm:ss') : ''}`,
    }));
  }

  private groupPhotosByDate = (photos: PhotoModel[]) => {
    return photos.reduce((groups, photo) => {
      const date = photo.createdAt ? format(new Date(photo.createdAt), 'dd.MM.yyyy') : this.i18n.t('poi:trail_camera.image_gallery.unknown_date');
      if (!groups[date]) groups[date] = [];
      groups[date].push(photo);
      return groups;
    }, {} as Record<string, PhotoModel[]>);
  }

  public handleDeleteImageConfirm = new AsyncTask(async () => {
    if (!this.photoToDelete || !this.props.poi) return;

    try {
      const response = await this.poiProxy.deletePhoto(this.photoToDelete);

      if (response?.ok) {
        const updatedPhotos = this.photos.filter(p => p.id !== this.photoToDelete?.id);
        const updatedPoi = Object.assign(
          Object.create(Object.getPrototypeOf(this.props.poi)),
          { ...this.props.poi, photos: updatedPhotos }
        );
        runInAction(() => {
          this.props.upsertPoi(updatedPoi);
          this.setPhotoToDelete(null);
          this.setLightboxIndex(null);
        });

        return this.notification.success('photo:delete.success');
      } else {
        return this.notification.error('photo:delete.error');
      }
    } catch (e) {
      console.error(`Error while handling photo delete: ${e}`);
      return this.notification.error('photo:delete.error');
    }
  });
}
