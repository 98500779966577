
export enum STRIPE_SUBSCRIPTION_STATUS {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid'
}

export enum STRIPE_SUBSCRIPTION_TYPE {
  SINGLE = 'single',
  GROUP = 'tiered'
}