import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { GroupSubscriptionUserModel } from '../../domain/model/GroupSubscriptionUserModel';
import { StripePriceTierModel } from '../../domain/model/stripe/StripePriceTierModel';
import { StripeProductModel } from '../../domain/model/stripe/StripeProductModel';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { StripeService } from '../../domain/service/StripeService';
import { SessionStore } from '../../domain/store/SessionStore';
import { STRIPE_PRICING_MODEL } from '../../shared/enum/stripe/stripePricingModel.enum';
import { currencySymbol } from '../../util/CurrencyHelper';

/**
 * Abstract base ViewModel providing core functionality for group pricing calculations.
 *
 * This ViewModel serves as the foundation for both initial group subscription purchases
 * and subsequent subscription updates. It handles common pricing logic and member management.
 *
 * Core responsibilities:
 * - Stripe product and pricing management
 * - Basic price calculations for group subscriptions
 * - Member management and assignment
 * - Currency handling
 *
 * Key features:
 * - Manages tiered pricing models (both GRADUATED and VOLUME)
 * - Handles member assignment and filtering
 * - Calculates base prices for single and multiple users
 * - Provides common discount calculation infrastructure
 *
 * Properties:
 * - product: The Stripe product configuration for group subscriptions
 * - areaRelatedMembers: All users connected to the group owner through any area
 * - assignedMembers: Set of users selected for group subscription
 *
 * Note: This is an abstract class extended by GroupSubscriptionVm and UpdateGroupSubscriptionVm
 * to provide specific implementation details for new purchases and updates respectively.
 */
export abstract class GroupPricingVm extends ViewModel {

  @observable
  public product: StripeProductModel | null = null;

  // * All users connected to the group owner through any area.
  @observable
  public areaRelatedMembers: GroupSubscriptionUserModel[] = [];

  // * Set of all selected users from the "AssignAreaMembers" modal.
  @observable
  public assignedMembers: Set<GroupSubscriptionUserModel> = new Set();

  constructor(
    @inject(StripeService) protected readonly stripeService: StripeService,
    @inject(I18nService) protected readonly i18n: I18nService,
    @inject(NotificationService) protected readonly notification: NotificationService,
    @inject(SessionStore) public readonly sessionStore: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get currency(): string {
    return currencySymbol(this.product?.price?.currency ?? 'eur');
  }

  @computed
  protected get tiers(): StripePriceTierModel[] {
    if (!this.product || !this.product.price?.tiers) {
      return [];
    }

    return this.product.price.tiers;
  }

  /**
   * It should be tiers of 2 items in GRADUATED pricing model.
   * In VOLUME pricing model are more tiers where price depends on quantity.
   * quantity === 1 -> use tiers[0] which has flatAmount
   * quantity > 1 -> use tiers[1] which has unitAmount
  */
  @computed
  public get priceForOneUser(): number {
    if (!this.tiers || this.tiers.length === 0) {
      return 0;
    }

    const firstTier = this.tiers[0];
    return Number(firstTier?.flatAmount?.toFixed(2)) ?? 0;
  }

  @computed
  public get priceForEveryNextUser(): number {
    if (!this.tiers || this.tiers.length === 0) {
      return 0;
    }

    return Number(this.tiers.find(tier => tier.upTo === null)?.unitAmount?.toFixed(2)) ?? this.priceForOneUser;
  }

  /**
   * Group candidates available for selection in the "AssignAreaMembers" modal.
   * The group owner is automatically included in purchasing the group subscription and thus not listed in this modal.
  */
  @computed
  public get candidatesForAssignment(): GroupSubscriptionUserModel[] {
    return this.areaRelatedMembers.filter(
      member => member.id !== this.sessionStore.userId && !this.assignedMembers.has(member)
    );
  }

  @computed
  public get ownerPriceWithoutDiscount(): number {
    return this.priceForOneUser;
  }

  @computed
  public get assignedMembersPriceWithoutDiscount(): number {
    return this.assignedMembers.size * this.priceForEveryNextUser;
  }

  public abstract get assignedMembersDiscount(): number
  public abstract get assignedMembersFinalPrice(): number
  public abstract get groupTotalPriceWithoutDiscount(): number;
  public abstract get groupTotalDiscount(): number;
  public abstract get groupTotalPriceAfterDiscount(): number;
  public abstract get groupMembersOverview(): Set<GroupSubscriptionUserModel>;

  @action
  public setAreaRelatedMembers = (relatedMembers: GroupSubscriptionUserModel[]) => {
    this.areaRelatedMembers = relatedMembers;
  }

  @action
  public setAssignedMembers = (assignedMembers: Set<GroupSubscriptionUserModel>) => {
    const sortedMembers = Array.from(assignedMembers).sort((a, b) => {
      // Non-pro users (no subscription) come first
      if (!a.subscription && b.subscription) return -1;
      if (a.subscription && !b.subscription) return 1;

      // Among pro users, sort by yearly subscription status
      if (a.isYearlySubscription !== b.isYearlySubscription) {
        return a.isYearlySubscription ? 1 : -1;
      }

      // If both or neither are yearly, maintain the original order
      return 0;
    });

    this.assignedMembers = new Set(sortedMembers);
  };


  @action
  private setProduct = (product: StripeProductModel) => {
    this.product = product;
  }

  protected getProducts = new AsyncTask(async (): Promise<void> => {
    const result = await this.stripeService.fetchProducts();

    //  * use this for a "STRIPE_PRICING_MODEL.VOLUME"
    /* const groupProduct = result
      .filter(product => product.tiered)
        && product.metadata.pricingModel === STRIPE_PRICING_MODEL.VOLUME
        && product.price.tiers.every(tier => tier.flatAmount === 0)
        ); */

    // * use this for a "STRIPE_PRICING_MODEL.GRADUATED"
    const groupProduct = result
      .filter(product => product.tiered)
      .find(product => product.metadata?.pricingModel === STRIPE_PRICING_MODEL.GRADUATED);

    if (groupProduct) {
      this.setProduct(groupProduct);
    }
  });

  protected getRelatedMembers = new AsyncTask(async () => {
    const relatedMembers = await this.stripeService.getAreasRelatedMembers();
    if (relatedMembers) {
      this.setAreaRelatedMembers(relatedMembers);
    }
  });


}
