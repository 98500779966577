import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-use';

import { getCurrencySymbol, ICurrencySymbols } from '@brixtol/currency-symbols';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

import { Assets } from '../../../assets';
import DeerSvg from '../../../assets/img/deer.svg';
import { useContainer } from '../../../domain/hooks/useContainer';
import { StripeProductModel } from '../../../domain/model/stripe/StripeProductModel';
import { SessionStore } from '../../../domain/store/SessionStore';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import { colors } from '../../../theme/options/palette/const/colors';

interface SubscriptionBoxProps {
  product: StripeProductModel;
  index: number;
  getTranslation: (fullName: string) => void;
  createCheckoutSession: (product: StripeProductModel, comingFromInAppBrowser?: boolean) => void;
}

export const SubscriptionBox = React.memo(function SubscriptionBox({ product, index, getTranslation, createCheckoutSession }: SubscriptionBoxProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const session = useContainer().get(SessionStore);
  const [shieldIconSize] = useState(54);
  const location = useLocation();

  const isPlusVersion = useMemo(() => product.name.includes('PLUS'), [product.name]);

  const productPrice = useMemo(() => {
    const { coupon, price } = product;
    const { priceAmount } = price;

    if (!coupon) {
      return priceAmount.toFixed(2);
    }

    const { amountOff, percentageOff } = coupon;

    if (amountOff) {
      return (priceAmount - amountOff).toFixed(2);
    }

    if (percentageOff) {
      return (priceAmount * (1 - percentageOff / 100)).toFixed(2);
    }

    return priceAmount.toFixed(2);
  }, [product]);

  const currencySymbol = (code: string): string => getCurrencySymbol(code.toUpperCase() as keyof ICurrencySymbols);

  const handleBuying = useCallback(() => {
    createCheckoutSession(product, location.pathname === AppRoutes.PricingEmbedded);
  }, [createCheckoutSession, product, location.pathname]);

  const isAnonymousSubscriptionPurchaseRoute = useMemo(() => location.pathname === AppRoutes.SubscriptionPurchase, [location.pathname]);

  return (
    <Grid item container margin="1%"
      sx={{
        background: `url(${DeerSvg}), linear-gradient(180deg, #02654E 0%, #02513F 100%)`,
        backgroundRepeat: 'no-repeat',
        boxShadow: '0px 6px 12px #00000029',
        borderRadius: '20px',
        width: '300px',
        '&:hover': {
          boxShadow: isPlusVersion ? '0 0 0 3pt #a2a2a2' : '0 0 0 3pt #F59F20',
        }
      }}
    >
      <Grid item container direction="column" alignItems="center" justifyContent="center">
        <Grid item container justifyContent="center" alignItems="center" minHeight={110}>
          <Grid item>
            {isPlusVersion
              ? <img src={Assets.shield_grey} height='60px' />
              : <img src={Assets.shield} height={`${shieldIconSize + index * 15}px`} />
            }
          </Grid>
        </Grid>
        <Grid item container direction="column" alignItems="center" rowGap={1} minHeight={140}>
          <Grid item>
            <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.8)">
              {t('payment:single_user', { version: isPlusVersion ? 'Plus' : 'PRO' })}
            </Typography>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minWidth="130px"
              height="44px"
              borderRadius="22px"
              sx={{ background: colors.paper }}
              py={1}
              px={3}
            >
              <Typography variant="h2" color={theme.palette.text.tertiary}>
                {`${productPrice}${currencySymbol(product.price!.currency)}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            {product.name && <Typography variant="subtitle1" color={colors.subscriptionBoxText}>
              {product.metadata?.locizeKey ? t(`payment:${product.metadata?.locizeKey}`) : getTranslation(product.name)}
            </Typography>}
          </Grid>
          {!isAnonymousSubscriptionPurchaseRoute &&
            session.allowTrialPeriod && <Grid item>
              <Typography variant='subtitle2' color={colors.subscriptionBoxText}>{t('payment:web_trial', { count: session.currentUser?.trialLength })}</Typography>
            </Grid>}
        </Grid>
        <Grid item container direction="column" alignItems="center" justifyContent="flex-end" p={2}>
          <Grid item>
            <Button sx={{
              backgroundColor: isPlusVersion ? '#b0b0b0' : colors.orange[400],
              color: colors.paper,
              width: '269px',
              height: '60px',
              borderRadius: '9px',
              '&:hover': {
                backgroundColor: isPlusVersion ? '#a2a2a2' : colors.orange[500],
              }
            }}
              onClick={handleBuying}
            >{t('payment:button_subscribe', { version: isPlusVersion ? 'Plus' : 'Pro' })}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

SubscriptionBox.displayName = 'SubscriptionBox';
