import { t } from 'i18next';
import React, { useCallback, useState } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';

import { Assets } from '../../assets';
import { FitContainerLoadingFallback } from '../../FitContainerLoadingFallback';
import { ImageGalleryTabStep } from './ImageGalleryRoute';

interface ImageLoaderProps {
  activeTab: ImageGalleryTabStep;
}
export const ImageLoader: React.FC<ImageLoaderProps> = function ImageLoader({ activeTab }: ImageLoaderProps) {
  const [loaded, setLoaded] = useState(false);
  const isSmartphoneScreen = useMediaQuery('(max-width:750px)');

  const handleImageLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" maxWidth={'100%'} height={'auto'}>
      {!loaded && <FitContainerLoadingFallback />}
      <Box visibility={loaded ? 'visible' : 'hidden'} display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
        <img src={Assets.image_gallery_dummy} onLoad={handleImageLoad} style={{ display: loaded ? 'block' : 'none', maxWidth: '100%', height: 'auto' }} />
        <Typography variant={isSmartphoneScreen ? 'h4' : 'h1'} marginTop={{ xs: 2, xl: 6 }}>
          {
            activeTab === 'entries'
              ? t('gallery:no_entries_images')
              : t('gallery:no_trail_cams_images')
          }
        </Typography>
      </Box>
    </Box>

  );
};
