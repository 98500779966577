import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Grid, Typography } from '@mui/material';

import { Assets } from '../../../../../assets';
import { useContainer } from '../../../../../domain/hooks/useContainer';
import { StripeService } from '../../../../../domain/service/StripeService';
import { TrackingEvent } from '../../../../../domain/service/tracking/TrackingEvent';
import { AppRoutes } from '../../../../../router/AppRoutesEnum';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { SubmitButton } from '../../../../../toolkit/components/SubmitButton';
import { GroupSubscriptionBox } from '../../../../pricing/components/GroupSubscriptionBox';
import { UserProfileProps } from '../../../UserProfileRoute';
import { UserProfileMapWrapper } from '../UserProfileMapWrapper';

export const UserProfileProUserRoute = observer(function UserProfileProUserRoute(props: UserProfileProps) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createPortalSession } = useContainer().get(StripeService);

  const createStripePortalSession = useCallback(() => {
    createPortalSession.run(TrackingEvent.MANAGE_SUBSCRIPTION);
  }, [createPortalSession]);

  const proWithoutGroupSubscription = () => (
    <Grid item container direction={'column'} alignItems="center" justifyContent={'center'} rowSpacing={3}>
      <Grid item textAlign={'center'}>
        <Typography variant="h5">{t('profile:feature_box.body')}</Typography>
      </Grid>
      <Grid item>
        {props.userProfileVm.groupProduct
          && <GroupSubscriptionBox product={props.userProfileVm.groupProduct} />}
      </Grid>
      {props.userProfileVm.enableManageStripeSubscription
        && <Grid item>
          <SubmitButton
            variant="contained"
            onClick={createStripePortalSession}
            sx={{
              backgroundColor: colors.secondary,
              '&:hover': {
                backgroundColor: colors.green[900],
                borderColor: colors.orange[400]
              },
              borderRadius: 100,
              color: colors.paper,
              fontSize: 15,
              minWidth: 200,
              height: 50,
              boxShadow: '3px 6px 6px #0000003D',
              border: 1,
              borderColor: colors.secondary,
            }}
          >{t('profile:button.manage_subscription')}</SubmitButton>
        </Grid>}
    </Grid>
  );

  const proAndGroupSubscriptionOwner = () => (
    <React.Fragment>
      <Grid item container direction={'column'} alignItems="center" justifyContent={'center'} rowSpacing={3}>
        <Grid item>
          <img src={Assets.group_discount_icon} />
        </Grid>
        <Grid item textAlign={'left'}>
          <Typography variant="h5">{t('profile:feature_box.group_owner.body_1')}</Typography>
        </Grid>
        <Grid item textAlign={'left'}>
          <Typography variant="h5">{t('profile:feature_box.group_owner.body_2')}</Typography>
        </Grid>
        <Grid item textAlign={'left'}>
          <Typography variant="h6">- {t('profile:feature_box.group_owner.body_3')}</Typography>
          <Typography variant="h6">- {t('profile:feature_box.group_owner.body_4')}</Typography>
          <Typography variant="h6">- {t('profile:feature_box.group_owner.body_5')}</Typography>
        </Grid>
        <Grid item textAlign={'left'}>
          <Typography variant="h5">{t('profile:feature_box.group_owner.body_6')}</Typography>
        </Grid>

        <Grid item container direction={'column'} alignItems="center" justifyContent={'center'} rowSpacing={2} mt={2}>

          {/* MANAGE SUBSCRIPTION BUTTON */}
          <Grid item>
            <SubmitButton
              onClick={() => navigate(AppRoutes.ManageGroup)}
              sx={{
                fontSize: 15,
                minWidth: 200,
                height: 50,
                boxShadow: '3px 6px 6px #0000003D',
              }}
            >{t('profile:button.manage_subscription')}</SubmitButton>
          </Grid>
        </Grid>

      </Grid>
    </React.Fragment>
  );

  const proAndGroupSubscriptionMember = () => (
    <Grid item container direction={'column'} alignItems="center" justifyContent={'center'} rowSpacing={3}>
      <Grid item>
        <img src={Assets.group_discount_icon} />
      </Grid>
      <Grid item textAlign={'center'}>
        <Typography variant="h5">{t('profile:feature_box.group_member.body_1')}</Typography>
      </Grid>
      <Grid item textAlign={'center'}>
        <Typography variant="h5">{t('profile:feature_box.group_member.body_2')}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <UserProfileMapWrapper>
      <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} pt={4} px={6}>
        {props.userProfileVm.isProWithoutGroupSubscription && proWithoutGroupSubscription()}
        {props.userProfileVm.isProAndGroupSubscriptionOwner && proAndGroupSubscriptionOwner()}
        {props.userProfileVm.isProAndGroupSubscriptionMember && proAndGroupSubscriptionMember()}
      </Grid>
    </UserProfileMapWrapper>
  );
});
