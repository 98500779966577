/* eslint-disable react/prop-types */
import { t } from 'i18next';
import React, { Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { XIcon } from '@HunterCompanion/hunter-design/icons';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid,
  IconButton, ThemeProvider, Typography, useMediaQuery
} from '@mui/material';

import { useThemeContext } from '../../theme/ThemeContextProvider';
import { NewSubmitButton } from './redesign-components/NewSubmitButton';
import { SvgWrapper } from './SvgWrapper';

export type DeleteDialogRef = {
  open: () => void,
  close: () => void,
  handleOpenDeleteDialog: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

interface DeleteDialogProps extends React.PropsWithChildren<unknown> {
  locizeNamespace: 'poi' | 'entry' | 'photo' | 'manage_group' | 'paths' | 'subzone' | 'quota_planning';
  onDelete: () => void;
  closeMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  titleKey?: string;
  descriptionKey?: string;
  agreeKey?: string;
  disagreeKey?: string;
}

export const DeleteDialog =
  React.forwardRef<DeleteDialogRef, DeleteDialogProps>(
    function DeleteDialog(props: DeleteDialogProps, ref: Ref<DeleteDialogRef>) {
      const [open, setOpen] = useState<boolean>(false);
      const { theme } = useThemeContext();
      const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
      const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

      // Track if the component is mounted
      const isMounted = React.useRef(true);

      useImperativeHandle(ref, () => ({
        open() {
          setOpen(true);
        },
        close() {
          setOpen(false);
        },
        handleOpenDeleteDialog(event?: React.MouseEvent<HTMLLIElement, MouseEvent>) {
          setOpen(true);
          event?.stopPropagation();
        },
      }));

      useEffect(() => {
        return () => {
          isMounted.current = false; // Set to false when the component is unmounted
        };
      }, []);

      const handleCloseDeleteDialog = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setOpen(false);
        if (props.closeMenu) {
          props.closeMenu(event);
        }
      }, [props]);

      const handleDeleteClick = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
        setIsSubmitting(true);
        try {
          await props.onDelete();
          if (isMounted.current) {
            setOpen(false); // Close the dialog after successful deletion
          }
        } finally {
          if (isMounted.current) {
            setIsSubmitting(false);
            handleCloseDeleteDialog(event);
          }
        }
      }, [handleCloseDeleteDialog, props]);

      return (
        <ThemeProvider theme={theme}>
          <Dialog
            open={open}
            onClose={handleCloseDeleteDialog}
            sx={{
              zIndex: 9999,
              '& .MuiDialog-container': {
                '& .MuiDialog-paper': {
                  margin: 2,
                  width: '100%',
                  maxWidth: {
                    xs: '90%',
                    sm: '500px',
                    md: '500px',
                    lg: '500px'
                  }
                }
              }
            }}
          >
            <Grid container direction={'column'}>
              {/* TITLE */}
              <Grid item>
                <DialogTitle>
                  <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item alignSelf={'center'} xs={11}>
                      <Typography variant='text_md_semibold' color={theme.palette.text.primary}>
                        {t(`${props.locizeNamespace}:${props.titleKey ?? 'delete.title'}`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={'end'}>
                      <IconButton onClick={handleCloseDeleteDialog}>
                        <SvgWrapper icon={XIcon} fill={theme.palette.icons?.secondary} width={19} height={19} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
              </Grid>

              {/* DIVIDER */}
              <Grid item>
                <Divider />
              </Grid>

              {/* BODY */}
              <Grid item>
                {props.children
                  ? props.children
                  : (
                    <DialogContent>
                      <DialogContentText>
                        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'}>
                          {t(`${props.locizeNamespace}:${props.descriptionKey ?? 'delete.description'}`)}
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                  )}
              </Grid>

              {/* DIVIDER */}
              <Grid item px={2}>
                <Divider />
              </Grid>

              {/* BUTTONS */}
              <Grid item>
                <DialogActions>
                  <Grid container justifyContent={'space-evenly'} columnSpacing={1}>
                    <Grid item xs={6}>
                      <Button
                        size={isMobileSize ? 'large' : 'small'}
                        color='secondary'
                        fullWidth
                        onClick={handleCloseDeleteDialog}>
                        {t(`${props.locizeNamespace}:${props.disagreeKey ?? 'delete.disagree'}`)}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <NewSubmitButton
                        size={isMobileSize ? 'large' : 'small'}
                        color='error'
                        fullWidth
                        submitting={isSubmitting}
                        onClick={handleDeleteClick}
                      >
                        {t(`${props.locizeNamespace}:${props.agreeKey ?? 'delete.agree'}`)}
                      </NewSubmitButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </Grid>
          </Dialog>
        </ThemeProvider>
      );
    });
