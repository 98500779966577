import 'yet-another-react-lightbox/captions.css';
import 'yet-another-react-lightbox/styles.css';

import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { PhotoAlbum, PhotoProps, RenderPhoto } from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';

import { Box } from '@mui/material';

import { colors } from '../../theme/options/palette/const/colors';
import { IGalleryImage, ImageGalleryVm } from './ImageGalleryVm';
import { ImageLoader } from './ImageLoader';
import { FitContainerLoadingFallback } from '../../FitContainerLoadingFallback';

interface ImageGalleryProps {
  vm: ImageGalleryVm;
}

export const ImageGallery = observer(function ImageGallery({ vm }: ImageGalleryProps) {

  const renderPhoto: RenderPhoto<IGalleryImage> = useCallback(
    ({ imageProps: { alt = '', style, ...rest } }: PhotoProps<IGalleryImage>) => (
      <img
        alt={alt}
        style={{
          ...style,
          borderRadius: '5px',
          boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
        {...rest}
      />
    ), []);

  const slides = useMemo(() => (
    vm.activeImages.map((slide) => ({
      ...slide,
      description: slide.data?.ownerName,
    }))
  ), [vm.activeImages]);

  return (
    <Box
      sx={[{
        bgcolor: colors.paper,
        boxShadow: '0px 3px 6px #707070',
        borderRadius: '21px',
        width: '100%',
        minHeight: { xs: '40vh', md: '55vh' },
        maxHeight: '75vh',
        padding: 4,
      },
      !vm.session.isProUser ? { height: { xl: '65vh' } } : {}
      ]}
    >
      {vm.isLoading ?
        <FitContainerLoadingFallback />
        : <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            ...(vm.activeImages.length === 0 && {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            })
          }}
        >
          {vm.activeImages.length === 0
            ? <ImageLoader activeTab={vm.activeTab} />
            : <React.Fragment>
              <PhotoAlbum
                photos={vm.activeImages}
                layout={'rows'}
                spacing={12}
                padding={0}
                targetRowHeight={350}
                renderPhoto={renderPhoto}
                onClick={(_event: React.MouseEvent<Element, MouseEvent>, _photo: IGalleryImage, index: number) => vm.setIndex(index)}
              />
              <Lightbox
                slides={slides}
                open={vm.index >= 0}
                index={vm.index}
                close={() => vm.setIndex(-1)}
                captions={{
                  descriptionTextAlign: 'start',
                }}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Captions]}
              />
            </React.Fragment>}
        </Box>}
    </Box>
  );
});
