import React, { BaseSyntheticEvent, useCallback, useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import { useContainer } from '../../../../domain/hooks/useContainer';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { env } from '../../../../env';
import { AppModal, AppModalRef } from '../../../../toolkit/components/AppModal';
import { GroupSubStepper } from './stepper/GroupSubStepper';

interface IGroupSubscriptionStepperModalProps {
  ownerPrice: string;
  memberPrice: string;
}

export const GroupSubscriptionStepperModal = function GroupSubscriptionStepperModal({ ownerPrice, memberPrice }: IGroupSubscriptionStepperModalProps) {

  const { track } = useContainer().get(TrackingService);

  const appModalRef: React.RefObject<AppModalRef> = useMemo(() => React.createRef(), []);

  const handleCloseModal = useCallback((e: BaseSyntheticEvent) => {
    appModalRef.current?.close();
    e.stopPropagation();
    track(TrackingEvent.GROUP_BUY_STEPPER_CLOSED);
  }, [appModalRef, track]);

  const handleClickOnFinish = useCallback(() => {
    track(TrackingEvent.GROUP_BUY_STEPPER_FINISHED);
  }, [track]);

  useEffectOnce(() => {
    track(TrackingEvent.GROUP_BUY_STEPPER_VIEW);
  });

  return (
    // ! TODO: remove when deploying to canary/staging/production
    <AppModal ref={appModalRef} isOpen={env.isDev && env.isLocalhost ? false : true} onClose={handleCloseModal}>
      <GroupSubStepper
        closeModal={handleCloseModal}
        ownerPrice={ownerPrice}
        memberPrice={memberPrice}
        onFinish={handleClickOnFinish}
      />
    </AppModal>
  );

};
