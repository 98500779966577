import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import * as queryString from 'query-string';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { AuthProxy } from '../../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../../domain/service/I18nService';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { transient } from '../../../../inversify/decorator';
import { Types } from '../../../../inversify/types';
import { AppRoutes } from '../../../../router/AppRoutesEnum';
import {
  UserLoginProviderRequestDto
} from '../../../../shared/dto/user/user.login.provider.request.dto';
import { LANGUAGE } from '../../../../shared/enum';

@transient()
export class ChangeLoginProviderVm extends ViewModel {

  @observable
  public token: string = '';

  @observable
  public email: string = '';

  @observable
  public queryLanguage: LANGUAGE = LANGUAGE.en;

  @observable
  public password: string = '';

  @observable
  public passwordShown: boolean = false;

  constructor(
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get queryParams(): { token: string, email: string, lang: LANGUAGE } {
    const params = queryString.parse(window.location.search);

    return {
      token: params.token as string,
      email: params.email as string,
      lang: params.lang as LANGUAGE,
    };
  }

  public override onInit(): void {
    const { token, lang, email } = this.queryParams;

    if (!token || !email) {
      this.notification.error(this.i18n.t('auth:change_login_provider.missing_required_parameters'));
      return;
    }

    runInAction(() => {
      this.token = token;
      this.queryLanguage = Object.values(LANGUAGE).includes(lang as LANGUAGE)
        ? lang as LANGUAGE
        : LANGUAGE.en;
      this.email = email;
    });
  }

  @action
  public setShowPassword = (passwordShown: boolean) => {
    this.passwordShown = passwordShown;
  }

  public changeLoginProvider = new AsyncTask(async (dto: UserLoginProviderRequestDto) => {
    try {
      const response = await this.authProxy.changeLoginProvider(dto);

      if (response.ok) {
        this.notification.success(this.i18n.t('auth:change_login_provider.success'));
        this.navigate(AppRoutes.Login, { state: this.email });
      } else {
        this.notification.error(this.i18n.t('auth:change_login_provider.error'));
      }
    } catch (error) {
      console.error('Change login provider error:', error);
      this.notification.error(this.i18n.t('auth:change_login_provider.error'));
    }
  })
}
