import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Photo } from 'react-photo-album';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { EntryModel } from '../../domain/model/EntryModel';
import { PhotoModel } from '../../domain/model/PhotoModel';
import { EntryProxy } from '../../domain/proxy/EntryProxy';
import { PoiProxy } from '../../domain/proxy/PoiProxy';
import { NotificationService } from '../../domain/service/NotificationService';
import { SessionStore } from '../../domain/store/SessionStore';
import { transient } from '../../inversify/decorator';
import { FEATURE } from '../../shared/enum';
import { ImageGalleryTabStep } from './ImageGalleryRoute';

export interface IGalleryImage extends Photo {
  photo: PhotoModel;
  isHiddenForNonProUsers?: boolean;
  data?: EntryModel;
}

interface ImageDimensions {
  imageHeight: number;
  imageWidth: number;
}

@transient()
export class ImageGalleryVm extends ViewModel {

  @observable
  public allEntries: EntryModel[] = [];

  @observable
  public filterByOwner: boolean = false;

  @observable
  public slides: IGalleryImage[] = [];

  @observable
  public index: number = -1;

  @observable
  public activeTab: ImageGalleryTabStep = 'entries';

  @observable
  public trailCamsImages: PhotoModel[] = [];

  @observable
  public isDimensionsLoading: boolean = true;

  constructor(
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(EntryProxy) private readonly entryProxy: EntryProxy,
    @inject(PoiProxy) private readonly poiProxy: PoiProxy,
    @inject(SessionStore) public readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  public override async onInit() {
    await Promise.all([
      this.getAllEntries.run(),
      this.getTrailCamsImages.run()
    ]);

    try {
      await Promise.all([
        this.setDimensionsOnEntriesPhotos(),
        this.setDimensionsOnTrailCamPhotos()
      ]);
    } finally {
      runInAction(() => {
        this.isDimensionsLoading = false;
      });
    }
  }


  @action
  public setIndex = (index: number) => {
    this.index = index;
  }

  @action
  public setAllEntries = (entries: EntryModel[]) => {
    this.allEntries = entries;
  }

  @action
  public setTrailCamsImages = (images: PhotoModel[]) => {
    this.trailCamsImages = images;
  }

  @action
  public setActiveTab = (tab: ImageGalleryTabStep) => {
    this.activeTab = tab;
  };

  @computed
  public get isLoading(): boolean {
    return this.getAllEntries.isBusy || this.getTrailCamsImages.isBusy || this.isDimensionsLoading;
  }

  @computed
  private get canSeeAllEntryImages() {
    return this.session.hasFeatureEnabled(FEATURE.MULTIPLE_IMAGES_VIEW);
  }

  @computed
  public get entries(): EntryModel[] {
    return this.allEntries
      // if we didn't sort array we would have to slice to get array from observableArray
      .slice()
      .filter((item) => !this.filterByOwner || !item.owner || item.owner.id === this.session.userId)
      .sort((item1, item2) => (item1.userDate && item2.userDate ? item2.userDate!.valueOf() - item1.userDate!.valueOf() : 0));
  }

  @computed
  public get entriesWithPhotos(): EntryModel[] {
    return this.entries.filter((entry: EntryModel) => entry.photos.length);
  }

  @computed.struct
  public get entryImages(): IGalleryImage[] {
    /**  because of reaction in onInit(), each entry in `this.entriesWithPhotos` have `photos` updated with height and width  */
    const images = this.entriesWithPhotos.map((entry: EntryModel) => {
      return entry.photos.map((photo: PhotoModel, index: number) => ({
        src: photo.url!,
        width: photo.width,
        height: photo.height,
        photo: photo,
        isHiddenForNonProUsers: index !== 0 && !this.canSeeAllEntryImages,
        data: entry,
      }));
    });

    let retImages: IGalleryImage[] = [];
    retImages = retImages.concat(...images);
    console.log('getentryImages ~ retImages:', retImages);
    return retImages;
  }

  @computed.struct
  public get trailCamGalleryImages(): IGalleryImage[] {
    return this.trailCamsImages.map((photo: PhotoModel) => ({
      src: photo.url!,
      width: photo.width,
      height: photo.height,
      photo: photo,
      isHiddenForNonProUsers: true,
    }));
  }

  @computed.struct
  public get activeImages(): IGalleryImage[] {
    return this.activeTab === 'entries' ? this.entryImages : this.trailCamGalleryImages;
  }

  private getAllEntries = new AsyncTask(async () => {
    try {
      const result = await this.entryProxy.getAllEntries();

      if (result.ok) {
        return this.setAllEntries(result.data);
      }

      this.notification.error('gallery:error.fetching_entries');
    } catch (error) {
      console.error('Error while fetching entries images: ', error);
      this.notification.error('gallery:error.fetching_entries');
    }
  });

  private getTrailCamsImages = new AsyncTask(async () => {
    try {
      const result = await this.poiProxy.getTrailCamsImages();

      if (result.ok) {
        this.setTrailCamsImages(result.data);
        this.setDimensionsOnTrailCamPhotos();
      }
    } catch (error) {
      console.error('Error while fetching trail cameras images: ', error);
      this.notification.error('gallery:error.fetching_trail_cams_images');
    }
  })

  private setDimensionsOnEntriesPhotos = async () => {
    try {
      await Promise.all(
        this.entriesWithPhotos.flatMap((entry) =>
          entry.photos.map(async (photo) => {
            const imgDim = await this.imageDimensions(photo.url!);
            runInAction(() => {
              photo.width = imgDim.imageWidth;
              photo.height = imgDim.imageHeight;
            });
          })
        )
      );
    } catch (error) {
      console.error('Failed to set dimensions for some entry photos:', error);
    }
  };


  private setDimensionsOnTrailCamPhotos = async () => {
    try {
      await Promise.all(
        this.trailCamsImages.map(async (photo) => {
          const imgDim = await this.imageDimensions(photo.url!);
          runInAction(() => {
            photo.width = imgDim.imageWidth;
            photo.height = imgDim.imageHeight;
          });
        })
      );
    } catch (error) {
      console.error('Failed to set dimensions for some trail cam photos:', error);
    }
  };

  private imageDimensionCache = new Map<string, ImageDimensions>();

  private imageDimensions = (imageUrl: string): Promise<ImageDimensions> => {
    // Check if the dimensions are already cached for the same url
    if (this.imageDimensionCache.has(imageUrl)) {
      return Promise.resolve(this.imageDimensionCache.get(imageUrl)!);
    }

    // If not cached, calculate and store them
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const dimensions = { imageHeight: img.height, imageWidth: img.width };
        this.imageDimensionCache.set(imageUrl, dimensions); // Cache the result
        resolve(dimensions);
      };
      img.onerror = reject;
      img.src = imageUrl;
    });
  };

}
