import { makeObservable, observable } from 'mobx';

import { StripeCouponResponseDto } from '../../../shared/dto/stripe/stripe.coupon.response.dto';

export class StripeCouponModel {
  @observable
  public id: string = '';

  @observable
  public name: string | null = null;

  @observable
  public amountOff: number | null = null;

  @observable
  public percentageOff: number | null = null;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: StripeCouponResponseDto | null) => {
    if (!dto) return null;

    const coupon = new StripeCouponModel();

    coupon.id = dto.id;
    coupon.name = dto.name;
    coupon.amountOff = dto.amountOff;
    coupon.percentageOff = dto.percentageOff;

    return coupon;
  }
}
