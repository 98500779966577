import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Box, Grid, TextField, Typography } from '@mui/material';

import { useVm } from '../../../../domain/hooks/useVm';
import { oldDesignTheme } from '../../../../theme/oldDesignTheme';
import { colors } from '../../../../theme/options/palette/const/colors';
import { Field } from '../../../../toolkit/components/form/Field';
import { Form } from '../../../../toolkit/components/form/Form';
import { FormSubmitButton } from '../../../../toolkit/components/form/FormSubmitButton';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { PasswordResetVm } from './PasswordResetVm';

export interface EmailStepProps {
  onEmail: (email: string, nextStep: boolean) => void;
}

export const EmailStep = observer(function EmailStep(props: EmailStepProps) {
  const vm = useVm(PasswordResetVm, props);
  const { t } = useTranslation();

  return (
    <Box>
      <Form onSubmit={vm.sendResetPasswordEmail}>
        <Grid container spacing={2}>
          <Grid item container justifyContent="center">
            <Box>
              <Typography color="textPrimary" variant="h1">
                {t('auth:reset_step1_title')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="subtitle1">
              {t('auth:reset_step1_message')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={2} marginBottom={4}>
              <Field name="email"
                fullWidth
                schema={
                  yup
                    .string()
                    .email(t('auth:validations.valid_email'))
                    .required(t('auth:validations.forgot_password.required_email'))
                }
              >
                <TextField
                  type="email"
                  label={t('auth:register_email_form_email')}
                  variant="filled"
                  fullWidth
                  sx={{
                    '& .MuiFilledInput-root': {
                      bgcolor: colors.paper,
                    },
                    '& .MuiInputBase-input': {
                      color: oldDesignTheme.palette.text.secondary,
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Field>
            </Box>
          </Grid>
          <Grid item container justifyContent="space-around">
            <Grid item>
              <SubmitButton
                type="button"
                onClick={vm.onBackToLogin}
                sx={{
                  minWidth: 228,
                  height: 48,
                  m: '4px 0',
                  color: oldDesignTheme.palette.text.tertiary,
                  bgcolor: 'transparent',
                  border: '1px solid #0D654E',
                  boxShadow: 'none',
                  '&:hover': {
                    bgcolor: oldDesignTheme.palette.secondary.main,
                    color: colors.paper,
                  },
                }}
              >
                {t('auth:reset_step1_form_cancel')}
              </SubmitButton>
            </Grid>
            <Grid item>
              <FormSubmitButton
                sx={{
                  minWidth: 228,
                  height: 48,
                  m: '4px 0',
                }}
              >
                {t('auth:reset_step1_form_submit')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
});
