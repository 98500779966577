import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';

import { Assets } from '../../../../../assets';
import { useVm } from '../../../../../domain/hooks/useVm';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../domain/model/TaskModel';
import { FitContainerLoadingFallback } from '../../../../../FitContainerLoadingFallback';
import { POI_TYPE } from '../../../../../shared/enum';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { ImageCarousel } from '../../../../../toolkit/components/image-carousel/ImageCarousel';
import { MapDistrictsVm } from '../../../MapDistrictsVm';
import { CustomizedMenu } from '../../entry/view-entry/components/CustomizedMenu';
import { IPoiTab } from '../new-poi/NewPoiVm';
import { ViewPoiAttributedTo } from './attributed-to/ViewPoiAttributedTo';
import { ViewPoiBlocked } from './booking/ViewPoiBlocked';
import { ViewPoiBooking } from './booking/ViewPoiBooking';
import { ViewConnectCamera } from './camera/ViewConnectCamera';
import { ViewCheckIns } from './check-ins/ViewCheckIns';
import { ViewImageGallery } from './image-gallery/ViewImageGallery';
import { ViewPoiInfo } from './info/ViewPoiInfo';
import { ShootingDirection } from './shooting-direction/ShootingDirection';
import { ViewTasks } from './tasks/ViewTasks';
import { PoiTabEnum, ViewPoiVm } from './ViewPoiVm';

export interface ViewPoiProps {
  poi: PoiModel;
  pois: PoiModel[];
  tasks: TaskModel[];
  onClose: () => void;
  districtsVm: MapDistrictsVm;
  onPoiEdit: (poi: PoiModel, tab: IPoiTab) => void;
  onPoiSave: () => void;
  onPoiDelete: (poi: PoiModel) => void;
  upsertPoi: (poi: PoiModel) => void;
}

export const ViewPoi = observer(function ViewPoi(props: ViewPoiProps) {
  const vm = useVm(ViewPoiVm, props);
  const { t } = useTranslation();

  const { poi, districtsVm, onClose, onPoiDelete, upsertPoi, tasks, pois, onPoiSave } = props;

  const handlePoiDelete = useCallback(() => onPoiDelete(poi), [onPoiDelete, poi]);

  const handlePoiUpsert = useCallback(() => {
    upsertPoi(poi);
    if (vm.tab.name === PoiTabEnum.IMAGE_GALLERY) {
      vm.refreshPoi.run(poi.id);
    }
  }, [upsertPoi, poi, vm.tab.name, vm.refreshPoi]);

  const handleRefresh = useCallback(async () => {
    if (!props.poi.id || vm.isRefreshing) return;
    await vm.refreshPoi.run(props.poi.id);
  }, [props.poi.id, vm]);

  const belongingDistrict = useMemo(() => {
    return districtsVm.allDistricts.find(d => d.id === poi.districtId);
  }, [districtsVm.allDistricts, poi.districtId]);

  const renderHeader = useMemo(() => (
    <Box paddingLeft={2.5} width="100%">
      {/* HEADER CONTROLS */}
      <Grid item container justifyContent={'flex-end'} alignItems="center">
        <Grid item>
          <IconButton
            onClick={handleRefresh}
            disabled={vm.isRefreshing}
            color="inherit"
            sx={{
              animation: vm.isRefreshing ? 'spin 1s linear infinite' : 'none',
              '@keyframes spin': {
                '0%': { transform: 'rotate(0deg)' },
                '100%': { transform: 'rotate(360deg)' }
              }
            }}
          >
            <AutorenewIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton color="inherit" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* TITLE AND ICON */}
      <Grid item container direction="column" paddingBottom={1} marginTop={3}>
        <Grid item container alignItems="center" textAlign={'start'} paddingBottom={props.poi.photos.length ? 1 : 0}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">{vm.typeText}</Typography>
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item xs={11}>
              <Typography variant="h4" sx={{ textShadow: '1px 1px 3px #00000029' }}>
                {vm.poiHelper.truncateString(props.poi.name, 22)}
              </Typography>
              <Typography variant="caption">{belongingDistrict?.name}</Typography>
            </Grid>
            {/* MENU */}
            {poi.id && (
              <Grid item xs={1}>
                <CustomizedMenu
                  locizeNamespace="poi"
                  onDelete={handlePoiDelete}
                  onUpsert={handlePoiUpsert}
                  canEditAndDeletePoi={vm.canEditAndDeletePoi}
                  item={poi}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ), [belongingDistrict?.name, handlePoiDelete, handlePoiUpsert, handleRefresh, poi, props.onClose, props.poi.name, props.poi.photos.length, vm.canEditAndDeletePoi, vm.isRefreshing, vm.poiHelper, vm.typeText]);

  const renderHeaderNoImages = useMemo(() => (
    <Grid container direction="column" justifyContent="center"
      sx={{
        background: vm.backgroundColor,
        color: colors.paper,
        paddingX: 2,
        paddingY: 1,
        minHeight: 150,
      }}
    >
      {renderHeader}
    </Grid>
  ), [vm.backgroundColor, renderHeader]);

  const renderHeaderWithImages = (
    <ImageCarousel
      item={poi}
      canDeleteImage={vm.canEditAndDeletePoi}
      locizeNamespace="poi"
      onUpsert={handlePoiUpsert}
      onClose={onClose}
    >
      {renderHeader}
    </ImageCarousel>
  );

  return (
    <Box height="100%" display="flex" flexDirection="column" >

      {vm.isRefreshing
        ? <FitContainerLoadingFallback />
        : <React.Fragment>
          {/* POI HEADER */}
          {!props.poi.photos.length || props.poi.type === POI_TYPE.CAMERA
            ? renderHeaderNoImages
            : renderHeaderWithImages}
          {/* TABS */}
          <Grid container columnSpacing={vm.hasDisabledTab ? 2 : 1} alignItems="center"
            paddingX={2} spacing={1} paddingY={2} sx={{ maxWidth: 'inherit', boxShadow: '0 8px 3px -6px #00000033' }}>
            {vm.poiTabs.map((tab) => (
              <Grid item key={tab.name} position="relative">
                <Chip
                  clickable
                  variant="outlined"
                  label={t(tab.name)}
                  onClick={() => vm.setTab(tab)}
                  sx={vm.tab.name === tab.name
                    ? {
                      borderColor: colors.green[800],
                      color: colors.green[800],
                      backgroundColor: colors.green[50],
                    }
                    : {}}
                />
                {!tab.enabled && (
                  <img src={Assets.shield} alt="Disabled" style={{ position: 'absolute', right: -10, top: -7, width: '24px' }} />
                )}
              </Grid>
            ))}
          </Grid>
          {/* TAB CONTENT */}
          {vm.tab.name === PoiTabEnum.TASKS && <ViewTasks poi={poi} tasks={tasks} pois={pois} district={districtsVm.selectedDistrict} />}
          {vm.tab.name === PoiTabEnum.BOOKING && (poi.blocked ? <ViewPoiBlocked /> : <ViewPoiBooking booking={poi.booking} />)}
          {vm.tab.name === PoiTabEnum.INFO && (
            <ViewPoiInfo poi={poi} info={poi.description} canEditAndDeletePoi={vm.canEditAndDeletePoi} onPoiEdit={vm.onPoiEdit} />
          )}
          {vm.tab.name === PoiTabEnum.ATTRIBUTED_TO && (
            <ViewPoiAttributedTo
              poi={poi}
              districts={districtsVm.allDistricts}
              onSelectDistrict={districtsVm.setSelectedDistrict}
              onPoiSave={onPoiSave}
              canEditAndDeletePoi={vm.canEditAndDeletePoi}
            />
          )}
          {vm.tab.name === PoiTabEnum.CHECK_INS && <ViewCheckIns poiId={poi.id} />}
          {vm.tab.name === PoiTabEnum.CONNECT_CAMERA && <ViewConnectCamera poi={poi} onRemoveConnection={vm.refreshPoi.run} />}
          {vm.tab.name === PoiTabEnum.IMAGE_GALLERY && <ViewImageGallery poi={poi} upsertPoi={handlePoiUpsert} />}
          {vm.tab.name === PoiTabEnum.SHOOTING_DIRECTION && (
            <ShootingDirection poi={poi} onShootingDirectionEdit={vm.onShootingDirectionEdit} canEditAndDeletePoi={vm.canEditAndDeletePoi} />
          )}
        </React.Fragment>}
    </Box>
  );
});
