import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';

import { NoImageIcon, TrashIcon } from '@HunterCompanion/hunter-design/icons';
import { Box, Grid, ThemeProvider, Typography } from '@mui/material';

import { PhotoModel } from '../../../../../../domain/model/PhotoModel';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';
import { DeleteDialog, DeleteDialogRef } from '../../../../../../toolkit/components/DeleteDialog';
import { AddImage, AddImageRef } from '../../../../../../toolkit/components/image-add/AddImage';
import { NewSubmitButton } from '../../../../../../toolkit/components/redesign-components/NewSubmitButton';
import { SvgWrapper } from '../../../../../../toolkit/components/SvgWrapper';
import { ViewImageGalleryVm } from './ViewImageGalleryVm';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { format } from 'date-fns';

export interface IViewGalleryProps {
  poi: PoiModel;
  upsertPoi: (poi: PoiModel) => void;
}

export const ViewImageGallery: React.FC<IViewGalleryProps> = observer(function ViewImageGallery(props) {
  const vm = useVm(ViewImageGalleryVm, props);
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const addImageRef = useRef<AddImageRef>(null);
  const deletePhotoDialogRef = useRef<DeleteDialogRef>(null);

  const handleImageClick = useCallback((index: number) => {
    vm.setLightboxIndex(index);
  }, [vm]);

  const handleUploadClick = useCallback(() => {
    addImageRef.current?.open();
  }, []);

  const handleDeleteImage = useCallback((photo: PhotoModel) => {
    vm.setPhotoToDelete(photo);
    deletePhotoDialogRef.current?.handleOpenDeleteDialog();
  }, [vm]);

  const emptyState = (
    <Box display={'flex'} flexDirection={'column'} gap={2} textAlign={'center'}>
      <SvgWrapper icon={NoImageIcon} width={36} height={36} fill={theme.palette.brand?.brand_color} />
      <Typography variant="text_md_semibold" color={theme.palette.text.primary}>
        {t('poi:trail_camera.image_gallery.no_images')}
      </Typography>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box display={'flex'} flexDirection={'column'} flex={'1 0 auto'}>
        <Box padding={2} width="100%" maxWidth="100%">
          {Object.keys(vm.groupedPhotos).length === 0 ?
            emptyState
            : <React.Fragment>
              {Object.entries(vm.groupedPhotos).map(([date, photos]) => (
                <Grid
                  container
                  key={`${date}-${photos.length}`}
                  marginBottom={2}
                  rowGap={1}
                  width="100%"
                >
                  <Grid item xs={12}>
                    <Typography variant="text_md_semibold" gutterBottom color={theme.palette.text.secondary}>
                      {date}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    {photos.map((photo, index) => (
                      <Grid item xs={12} sm={6} key={`${index}-${photo.id}`}>
                        <Box
                          component="img"
                          src={photo.url || ''}
                          alt={photo.name || `${t('poi:trail_camera.image_gallery.image.name')} - ${index}`}
                          onClick={() => handleImageClick(photos.indexOf(photo))}
                          sx={{
                            width: '100%',
                            height: 120,
                            objectFit: 'cover',
                            borderRadius: 1,
                            boxShadow: 1,
                            cursor: 'pointer',
                          }}
                        />
                        <Grid container justifyContent={'space-between'} alignContent={'center'}>
                          <Grid item>
                            <Typography variant="text_xs_regular" color={theme.palette.text.secondary} align="left">
                              {photo.createdAt && `${format(new Date(photo.createdAt), 'HH:mm')}h`}
                            </Typography>
                          </Grid>
                          <Grid item onClick={() => handleDeleteImage(photo)} sx={{ cursor: 'pointer' }} alignSelf={'center'}>
                            <SvgWrapper icon={TrashIcon} fill={theme.palette.background.error_default} width={18} height={18} />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
              <Lightbox
                slides={vm.slides}
                open={vm.lightboxIndex !== null}
                index={vm.lightboxIndex || 0}
                close={() => vm.setLightboxIndex(null)}
                plugins={[Fullscreen, Captions]}
                captions={{
                  descriptionTextAlign: 'start',
                }}
              />
            </React.Fragment>
          }
        </Box>
        <Box
          marginTop="auto"
          position="sticky"
          bgcolor="white"
          borderTop={1}
          bottom={0}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          minHeight="70px"
          paddingX={3}
          gap={1}
          zIndex={2}
        >
          <NewSubmitButton
            color="secondary"
            sx={{ minWidth: '170px' }}
            onClick={handleUploadClick}
          >
            {t('poi:trail_camera.image_gallery.cta.upload_photos')}
          </NewSubmitButton>
        </Box>
      </Box>
      <AddImage
        ref={addImageRef}
        item={props.poi}
        upsert={(item) => props.upsertPoi(item as PoiModel)}
        closeMenu={() => addImageRef.current?.close()}
      />
      <DeleteDialog
        ref={deletePhotoDialogRef}
        locizeNamespace="photo"
        onDelete={vm.handleDeleteImageConfirm.run}
      />
    </ThemeProvider>
  );
});
