import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs, ThemeProvider } from '@mui/material';

import { useVm } from '../../domain/hooks/useVm';
import { LoadingFallback } from '../../LoadingFallback';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import { Background } from '../../toolkit/components/Background';
import { IconAndTitle } from '../../toolkit/components/IconAndTitle';
import { ProBlockerModal } from '../pro-blocker/ProBlockerModal';
import { ImageGallery } from './ImageGallery';
import { ImageGalleryVm } from './ImageGalleryVm';

// Define the types for the tab roles
export type ImageGalleryTabStep = 'entries' | 'trailCams';

interface TabItem {
  step: ImageGalleryTabStep;
  label: string;
}


export const ImageGalleryRoute = observer(function ImageGalleryRoute() {
  const vm = useVm(ImageGalleryVm);
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = useCallback((_event: React.ChangeEvent<{}>, newValue: ImageGalleryTabStep) => {
    vm.setActiveTab(newValue);
  }, [vm]);

  const tabs: TabItem[] = useMemo(() => [
    { step: 'entries', label: t('gallery:tab.entries') },
    { step: 'trailCams', label: t('gallery:tab.trail_cams') },
  ], [t]);

  if (vm.isLoading) {
    return <LoadingFallback />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Background paddingX={{ xl: '8%' }}>
        <Box display="flex" flexDirection="column">
          <IconAndTitle title={t('gallery:title')} subtitle={`${vm.activeImages.length} ${t('gallery:subtitle')}`} />

          <Box paddingTop={1} paddingBottom={4} display="flex" justifyContent="flex-start" alignItems="center" width={{ xs: '100%', sm: 500 }}>
            <Tabs
              value={vm.activeTab}
              onChange={handleTabChange}
              TabIndicatorProps={{ hidden: true }}
              sx={{
                width: '100%',
                borderRadius: 2,
                backgroundColor: theme.palette.background.container_2,
                '& .MuiTab-root': {
                  color: theme.palette.text.primary,
                  ...theme.typography.text_sm_semibold,
                },
                '& button.Mui-selected': {
                  borderRadius: 2,
                  backgroundColor: theme.palette.brand?.brand_color,
                  color: theme.palette.system?.white,
                },
              }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.step}
                  value={tab.step}
                  label={tab.label}
                  sx={{ textTransform: 'none', width: '50%' }}
                />
              ))}
            </Tabs>
          </Box>
          <ImageGallery vm={vm} />
        </Box>
        {!vm.session.isProUser && <ProBlockerModal />}
      </Background>
    </ThemeProvider>
  );
});
