import { makeObservable, observable } from 'mobx';

import { StripeProductResponseDto } from '../../../shared/dto/stripe/stripe.product.response.dto';
import { StripeCouponModel } from './StripeCouponModel';
import { StripePriceModel } from './StripePriceModel';
import { StripeProductMetadataModel } from './StripeProductMetadataModel';

export class StripeProductModel {
  @observable
  public id: string = '';

  @observable
  public name: string = '';

  @observable
  public price: StripePriceModel;

  @observable
  public metadata: StripeProductMetadataModel | null;

  @observable
  public coupon: StripeCouponModel | null = null;

  @observable
  public tiered: boolean = false;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: StripeProductResponseDto) => {
    const product = new StripeProductModel();

    product.id = dto.id;
    product.name = dto.name;
    product.price = StripePriceModel.fromDto(dto.price);
    product.metadata = StripeProductMetadataModel.fromDto(dto.metadata);
    product.coupon = StripeCouponModel.fromDto(dto.coupon);
    product.tiered = dto.tiered;

    return product;
  }
}
