import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Box, Grid, TextField, Typography } from '@mui/material';

import { useVm } from '../../../../domain/hooks/useVm';
import {
  UserLoginProviderRequestDto
} from '../../../../shared/dto/user/user.login.provider.request.dto';
import { oldDesignTheme } from '../../../../theme/oldDesignTheme';
import { colors } from '../../../../theme/options/palette/const/colors';
import { Field } from '../../../../toolkit/components/form/Field';
import { Form } from '../../../../toolkit/components/form/Form';
import { FormSubmitButton } from '../../../../toolkit/components/form/FormSubmitButton';
import { Password } from '../../../../toolkit/components/Password';
import { ChangeLoginProviderVm } from './ChangeLoginProviderVm';

export const ChangeLoginProvider = observer(function ChangeLoginProvider() {
  const vm = useVm(ChangeLoginProviderVm);

  const { i18n, t } = useTranslation();

  const onSubmit = useCallback(({ email, password }) => {
    const dto: UserLoginProviderRequestDto = {
      token: vm.token,
      email: email,
      password: password,
    };

    vm.changeLoginProvider.run(dto);
  }, [vm]);

  useEffect(() => {
    if (vm.queryLanguage && vm.queryLanguage !== i18n.language) {
      i18n.changeLanguage(vm.queryLanguage);
    }
  }, [i18n, vm.queryLanguage]);

  return (
    <Box>
      <Form onSubmit={onSubmit} defaultValues={{ email: vm.email }}>
        <Grid container spacing={2}>
          <Grid item container justifyContent="center">
            <Box>
              <Typography color="textPrimary" variant="h1">
                {t('auth:change_login_provider.title')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={2} marginBottom={4}>
              <Field name="email"
                fullWidth
                schema={
                  yup
                    .string()
                    .email(t('auth:validations.valid_email'))
                    .required(t('auth:validations.forgot_password.required_email'))
                }
              >
                <TextField
                  type="email"
                  label={t('auth:register_email_form_email')}
                  variant="filled"
                  fullWidth
                  sx={{
                    '& .MuiFilledInput-root': {
                      bgcolor: colors.paper,
                    },
                    '& .MuiInputBase-input': {
                      color: oldDesignTheme.palette.text.secondary,
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Field>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Field name="password"
              fullWidth
              schema={
                yup
                  .string()
                  .min(8, t('auth:validations_invalid_password'))
                  .required(t('auth:validations.login.required_password'))
              }>
              <Password
                label={t('auth:change_login_provider.password')}
                sx={{
                  '& .MuiFilledInput-root': {
                    backgroundColor: colors.paper,
                  },
                  '& .MuiInputBase-input': {
                    color: oldDesignTheme.palette.text.secondary,
                    fontSize: '18px',
                    fontWeight: 'bold',
                  },
                }}
              />
            </Field>
            <Typography color="textSecondary" variant="body2">
              {t('auth:change_login_provider.password_hint')}
            </Typography>
          </Grid>
          <Grid item container justifyContent="space-around">
            <Grid item>
              <FormSubmitButton
                sx={{
                  minWidth: 228,
                  height: 48,
                  m: '4px 0',
                }}
              >
                {t('auth:change_login_provider.cta')}
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
});
