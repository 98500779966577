import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Onboarding_05_Icon } from '@HunterCompanion/hunter-design/icons';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';

import { useContainer } from '../../../../domain/hooks/useContainer';
import { StripeService } from '../../../../domain/service/StripeService';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../toolkit/components/SvgWrapper';
import { ManageGroupVm } from '../ManageGroupVm';

interface IManageGroupProductInfo {
  manageGroupVm: ManageGroupVm;
}

export const ManageGroupProductInfo: React.FC<IManageGroupProductInfo> = function ManageGroupProductInfo({ manageGroupVm }: IManageGroupProductInfo) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const { createPortalSession } = useContainer().get(StripeService);

  const createStripePortalSession = useCallback(() => {
    createPortalSession.run(TrackingEvent.GROUP_MANAGE_STRIPE_SELECTED);
  }, [createPortalSession]);

  return (
    <Grid container border={`1px solid ${theme.palette.borderDividers?.lighter_darker}`} borderRadius={2} padding={2}>
      {/* INFO BLOCK */}
      <Grid container justifyContent={'space-between'} alignItems={'center'} rowGap={2}>
        {/* INFO BLOCK 1*/}
        <Grid container item xs={12} sm={6} alignItems={'center'} gap={1}>
          <Grid item>
            <SvgWrapper icon={Onboarding_05_Icon} fill={undefined} width={50} height={40} />
          </Grid>
          <Grid item xs>
            <Typography variant='text_sm_semibold' color={theme.palette.text.primary}>
              {t('group_subscription:manage_group.info_block.subtitle')}
            </Typography>
            <Typography mb={0.5} variant={isMobileSize ? 'headingSubtitleMobile' : 'headingSubtitleDesktop'} color={theme.palette.text.primary}>
              {t('group_subscription:manage_group.info_block.title')}
            </Typography>
            {manageGroupVm.isIncompleteOrPastDue
              ? <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
                {t('manage_group:header.title_update_payment')}
              </Typography>
              : <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
                {t('group_subscription:manage_group.info_block.overview', {
                  quantity: manageGroupVm.groupSubscriptionInfo.quantity,
                  status: manageGroupVm.groupSubscriptionStatusTranslation,
                  action: manageGroupVm.groupSubscriptionActionTranslation,
                  renewalDate: manageGroupVm.expiresAt
                })}
              </Typography>}
          </Grid>
        </Grid>

        {/* INFO BLOCK 2 */}
        <Grid container item xs={12} sm={4} md={3}>
          <Button fullWidth color='secondary' onClick={createStripePortalSession}>
            {t('profile:button.manage_subscription')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
