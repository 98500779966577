import { observer } from 'mobx-react-lite';
import React, { BaseSyntheticEvent, useCallback, useState } from 'react';
import { useImperativeHandle } from 'react';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import { Grid, IconButton, Modal, ThemeProvider } from '@mui/material';
import { SvgWrapper } from './SvgWrapper';
import { XIcon } from '@HunterCompanion/hunter-design/icons';

export type AppModalRef = {
  open: () => void,
  close: () => void,
}

interface IAppModalProps extends React.PropsWithChildren<unknown> {
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: (e: BaseSyntheticEvent) => void; // * for tracking purposes
}

export const AppModal = observer(
  React.forwardRef<AppModalRef, IAppModalProps>(
    function AppModal({ isOpen = false, children, onClose }: IAppModalProps, ref) {
      const { theme } = useThemeContext();
      const [open, setOpen] = useState<boolean>(isOpen);

      useImperativeHandle(ref, () => ({
        open() {
          setOpen(true);
        },
        close() {
          setOpen(false);
        }
      }));

      const handleCloseModal = useCallback((e: BaseSyntheticEvent) => {
        setOpen(false);
        e.stopPropagation();
        onClose?.(e);
      }, [onClose]);

      return (
        <ThemeProvider theme={theme}>
          <Modal
            open={open}
            disableAutoFocus={true}
            onClose={handleCloseModal}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              container
              direction={'column'}
              bgcolor={theme.palette.background.surface}
              border={`1px solid ${theme.palette.borderDividers?.divider}`}
              borderRadius={1}
              width={{ xs: '90%', sm: 450, md: 600 }}
              minHeight={{ md: 600 }}
            >
              {/* CLOSE MODAL */}
              <Grid container item padding={1}>
                <Grid flexGrow={1} />
                <Grid item>
                  <IconButton onClick={handleCloseModal}>
                    <SvgWrapper icon={XIcon} fill={theme.palette.icons?.secondary} width={19} height={19} />
                  </IconButton>
                </Grid>
              </Grid>
              {/* BODY */}
              <Grid item container flexGrow={1}>
                {children}
              </Grid>
            </Grid>
          </Modal>
        </ThemeProvider>
      );
    })
);
