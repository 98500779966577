import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { Grid, ThemeProvider, useMediaQuery } from '@mui/material';

import { useVm } from '../../../domain/hooks/useVm';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { LoadingFallback } from '../../../LoadingFallback';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { GroupTitle } from '../group-subscription/components/GroupTitle';
import { ManageGroupDescription } from './components/ManageGroupDescription';
import { ManageGroupProductInfo } from './components/ManageGroupProductInfo';
import { ManageGroupVm } from './ManageGroupVm';
import { ManageGroupOverviewTable } from './overview/ManageGroupOverviewTable';

export const ManageGroup = observer(function ManageGroup() {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();

  const vm = useVm(ManageGroupVm);

  const description = useMemo(() => <ManageGroupDescription />, []);

  const manageGroupOverviewTable = useMemo(() => <ManageGroupOverviewTable manageGroupVm={vm} />, [vm]);

  const groupProductInfo = useMemo(() => <ManageGroupProductInfo manageGroupVm={vm} />, [vm]);

  useEffectOnce(() => {
    vm.tracking.track(TrackingEvent.GROUP_MANAGE_MEMBERSHIP_VIEW);
  });

  if (vm.dataLoadingInProgress) {
    return <LoadingFallback />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems={'center'} justifyContent={'center'} bgcolor={theme.palette.background.surface}>
        <Grid container direction={'column'} paddingY={{ xs: 3, md: 6 }} rowGap={isMobileSize ? 2 : 4} maxWidth={{ xs: '95%', sm: '90%', lg: '70%', xl: '60%' }} alignItems={'center'}>
          {/* TITLE */}
          <GroupTitle title={t('group_subscription:title.manage_group')} />

          {/* DESCRIPTION */}
          {description}

          {/* OVERVIEW TABLE */}
          {manageGroupOverviewTable}

          <Grid item container flexGrow={1} />

          {/* MANAGE YOUR PLAN */}
          {groupProductInfo}

        </Grid>

      </Grid>
    </ThemeProvider>
  );
});
