export enum AppRoutes {
  DownloadAppRoute = '/download',
  UserProfile = '/profile',
  Pricing = '/pricing',
  PricingEmbedded = '/pricing-embedded',
  GroupSubscription = '/pricing/group-subscription',
  CanceledPayment = '/canceled-payment',
  SuccessfulPayment = '/successful-payment',
  SuccessfulPaymentUnauthorized = '/successful-payment-unauthorized',
  SuccessfulPaymentEmbedded = '/successful-payment-embedded',
  Login = '/auth/login',
  Register = '/auth/register',
  EmailRegister = '/auth/email-register',
  AcceptGroupInvite = '/auth/accept-invite',
  SocialMediaRegister = '/auth/social-media-register',
  ForgotPassword = '/auth/forgot-password',
  ChangeLoginProvider = '/auth/change-login-provider',
  NewPassword = '/auth/new-password',
  SubscriptionPurchase = '/auth/subscription-purchase',
  Map = '/map/districts',
  Gallery = '/image-gallery',
  ManageGroup = '/profile/manage-group',
  AddMembersToGroup = '/profile/manage-group/add-member',
}
